import { ErrorWrapper } from '@web/components/error-wrapper';
import { BaseLayout } from '@web/features/layouts/base';

/**
 * # Custom404
 * @packageDescription
 *
 * Empty 404 page
 */
export function Custom404() {
  return (
    <BaseLayout withHomeButton>
      <ErrorWrapper title="404 :(" error={{ message: 'Page Not Found' }} />
    </BaseLayout>
  );
}

export default Custom404;
